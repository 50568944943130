import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['time']

  connect() {
    this.convert()
  }

  convert() {
    const unconvertedTime = this.timeTarget.dataset.unconvertedTime;
    const timeFormat = this.timeTarget.dataset.timeFormat;
    const updatedTime = strftime(timeFormat, new Date(unconvertedTime))
    this.timeTarget.innerHTML = `${updatedTime}`;
  }
}
