import { Controller } from 'stimulus'

export default class extends Controller {
  /**
   * Set up element "target"
   * Applied to the trigger elements and fieldsets in the form
   * @see https://stimulus.hotwired.dev/reference/targets
   */
  static targets = ['addButton', 'fieldset', 'removeButton', 'gender', 'genderSelfDescription', 'destroy']

  /**
   * Set up element "values"
   * @see https://stimulus.hotwired.dev/reference/values
   */
  static values = { count: Number, max: Number, min: Number }

  /**
   * Set up CSS classes
   * @see https://stimulus.hotwired.dev/reference/css-classes
   */
  static classes = ['fieldsetHidden', 'fieldsetLastVisibleMember']

  /**
   * Initialise gender fields on page load
   */
  connect() {
    this.genderChange()
  }

  /**
   * Add a team member
   */
  add() {
    if (this.countValue < this.maxValue) {
      this.countValue += 1
    }
  }

  /**
   * Remove a team member
   */
  remove() {
    if (this.countValue > this.minValue) {
      this.countValue -= 1
    }
  }

  /**
   * Update members and buttons when count changes
   */
  countValueChanged() {
    this.updateMembers()
    this.updateButtons()
    this.genderChange()
  }

  /**
   * Update visibility of gender self description field based on value in associated gender field.
   */
  genderChange() {
    this.genderTargets.forEach((element, index) => {
      const fieldset = this.genderSelfDescriptionTargets[index]

      if (element.value === 'prefers_to_self_describe') {
        this.fieldsetShow(fieldset)
      } else {
        this.fieldsetHide(fieldset)
      }
    })
  }

  /**
   * Enable/disable add and remove buttons based on current count.
   */
  updateButtons() {
    this.addButtonTarget.disabled = this.countValue === this.maxValue
    this.removeButtonTarget.disabled = this.countValue === this.minValue
  }

  /**
   * Update visibility of team member fields based on the current count.
   *
   * Also enable/disable the associated destroy field so that the record is
   * deleted from the database if a team member is removed.
   */
  updateMembers() {
    this.fieldsetTargets.forEach((element, index) => {
      const destroyField = this.destroyTargets[index]

      element.classList.remove(this.fieldsetLastVisibleMemberClass)

      if (index < this.countValue) {
        this.fieldsetShow(element)
        destroyField.disabled = true

        if (index > this.minValue - 1 && index === this.countValue - 1) {
          element.classList.add(this.fieldsetLastVisibleMemberClass)
        }

        return
      }

      this.fieldsetHide(element)
      destroyField.disabled = false
    })
  }

  /**
   * Hide a fieldset.
   *
   * @param {Object} fieldsetElement The fieldset element to hide
   *
   * Adds the fieldsetHiddenClass (defined in the `static classes` array)
   * Prevents the form from submitting the fieldset data
   *
   * Also resets any data in the fieldset's input and select fields
   */
  fieldsetHide(fieldsetElement) {
    const fieldset = fieldsetElement

    fieldset.querySelectorAll('input').forEach((field) => {
      field.value = ''
    })

    fieldset.querySelectorAll('select').forEach((field) => {
      field.selectedIndex = 0
    })

    fieldset.classList.add(this.fieldsetHiddenClass)
    fieldset.disabled = true
  }

  /**
   * Show a fieldset.
   *
   * @param {Object} fieldsetElement The fieldset element to show
   *
   * Removes the fieldsetHiddenClass (defined in the `static classes` array)
   * Allows the form to submit the fieldset data
   *
   */
  fieldsetShow(fieldsetElement) {
    const fieldset = fieldsetElement

    fieldset.classList.remove(this.fieldsetHiddenClass)
    fieldset.disabled = false
  }
}
