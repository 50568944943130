import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import Dropzone from 'dropzone'

/**
 * This is a Stimulus controller used in dropzone file-upload at:
 * app/views/teams/experiments/_form.html.erb
 *
 * For further information, see the Stimulus docs on Controllers here:
 * https://stimulus.hotwired.dev/reference/controllers
 * and dropzone info:
 * https://web-crunch.com/posts/rails-drag-drop-active-storage-stimulus-dropzone
 * https://docs.dropzone.dev/
 */

/*
 * Prevent "Dropzone already attached" errors by turning off autodiscovery
 * https://docs.dropzone.dev/getting-started/setup/declarative#the-auto-discover-feature
 */
Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = [
    'fileZone',
    'enableCheckbox',
    'fileInput',
    'uploadHint',
    'acceptTermsHint',
    'hiddenInput',
    'nextButton',
    'warning',
    'warningText',
    'loader',
    'info',
    'success',
  ]

  static values = {
    maxFileSize: Number,
    acceptedFiles: String,
    genericError: String,
    fileSizeError: String,
    contentTypeError: String,
    fileCountError: String,
    submitDisabled: { type: Boolean, default: true },
  }

  handleEnableChange(e) {
    e.target.checked ? this.enableForm() : this.disableForm()
  }

  // eslint-disable-next-line class-methods-use-this
  hide(element) {
    // eslint-disable-next-line no-param-reassign
    element.style.display = 'none'
  }

  // eslint-disable-next-line class-methods-use-this
  show(element) {
    // eslint-disable-next-line no-param-reassign
    element.style.display = ''
  }

  showIdleState() {
    this.show(this.infoTarget)
    this.hide(this.loaderTarget)
    this.hide(this.warningTarget)
    this.hide(this.successTarget)
  }

  showLoadingState() {
    this.show(this.loaderTarget)
    this.hide(this.infoTarget)
    this.hide(this.warningTarget)
    this.hide(this.successTarget)
  }

  showErrorState(message) {
    this.show(this.warningTarget)
    this.hide(this.infoTarget)
    this.hide(this.loaderTarget)
    this.hide(this.successTarget)
    this.warningTextTarget.innerText = message
  }

  showSuccessState() {
    this.show(this.successTarget)
    this.hide(this.infoTarget)
    this.hide(this.loaderTarget)
    this.hide(this.warningTarget)
  }

  disableForm() {
    this.dropZone.disable()
    this.fileInputTarget.disabled = true
    this.hide(this.uploadHintTarget)
    this.show(this.acceptTermsHintTarget)
  }

  enableForm() {
    this.dropZone.enable()
    this.show(this.uploadHintTarget)
    this.hide(this.acceptTermsHintTarget)
  }

  connect() {
    this.boundHandle = this.handleEnableChange.bind(this)
    // this preventDefault prevents unwanted scrolling when using form with keyboard
    this.fileZoneTarget.addEventListener('click', (e) => e.preventDefault())

    if (this.hasEnableCheckboxTarget) {
      this.enableCheckboxTarget.addEventListener('click', this.boundHandle)
      // eslint-disable-next-line no-unused-expressions
      this.enableCheckboxTarget.checked ? this.enableForm() : this.disableForm()
    }
  }

  disconnect() {
    if (this.hasEnableCheckboxTarget) {
      this.enableCheckboxTarget.removeEventListener('click', this.boundHandle)
    }
  }

  upload(file) {
    new DirectUpload(file, this.fileInputTarget.dataset.directUploadUrl, this).create(
      (storageServiceError, attributes) => {
        if (storageServiceError) {
          this.dropZone.emit('error', file, this.genericErrorValue)
          this.dropZone.emit('complete', file)
        } else {
          this.showSuccessState()
          this.dropZone.emit('success', file)
          this.dropZone.emit('complete', file)
          this.hiddenInputTarget.value = attributes.signed_id
          this.nextButtonTarget.disabled = false
        }
      }
    )
  }

  initialize() {
    this.nextButtonTarget.disabled = this.submitDisabledValue
    /*
     * Setting this to empty to prevent errors when updating the record without uploading a new file.
     * Without this, a string along the lines of "#<ActiveStorage::Attached::One:0x00007f7dcaa98f68>"
     * is set, which then causes an invalid signature error on submission
     */
    this.hiddenInputTarget.value = ''
    this.showIdleState()

    this.dropZone = new Dropzone(this.fileZoneTarget, {
      url: this.fileInputTarget.dataset.directUploadUrl,
      headers: this.headers,
      maxFiles: 1,
      maxFilesize: this.maxFileSizeValue,
      acceptedFiles: this.acceptedFilesValue,
      addRemoveLinks: this.addRemoveLinks,
      autoQueue: false,
      createImageThumbnails: false,
      disablePreviews: true,
      // Any message that Dropzone provides can be overridden for locale
      // See all messages at https://github.com/dropzone/dropzone/blob/main/src/options.js#L297
      // Defaults are included here, because Dropzone will break with null dict values
      dictInvalidFileType: this.contentTypeErrorValue || 'Incorrect file type.',
      dictFileTooBig: this.fileSizeErrorValue || 'File too large',
      dictMaxFilesExceeded: this.fileCountErrorValue || 'You may only upload one file.',
    })

    /* Catches errors from Dropzone, but not S3/etc */
    this.dropZone.on('error', (file, message) => {
      this.showErrorState(message)
      this.dropZone.removeFile(file)
    })

    this.dropZone.on('addedfile', (file) => {
      this.dropZone.removeAllFiles()
      this.showLoadingState()
      /* `.accept` is dropZone's method to validate the file according to the config params.
          It's called under the hood, but we call it here explicitly because it allows
          us to avoid a race condition where file.status is not set fast enough, and hence
          not usable to check if the file is acceptable for upload. `.accept` has no side-effects.
       */
      this.dropZone.accept(file, (err) => {
        if (!err) this.upload(file)
      })
    })
  }
}
