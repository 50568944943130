import { Controller } from 'stimulus'

export default class extends Controller {
  /**
   * Validations defined here so we can consolidate criteria being hit into one `validated` field
   * This means one error message for the user, not N error messages. Reduces visual clutter.
   * @see https://stimulus.hotwired.dev/reference/values
   */
  static values = {
    validations: ['isErrorFree', 'usedLEDs', 'readColour'],
  }

  /**
   * Set up element "target"
   * Applied to the trigger elements and fieldsets in the form
   * @see https://stimulus.hotwired.dev/reference/targets
   */
  static targets = [
    'editor',
    'code',
    'message',
    'validated',
    'submit',
    // Add targets for each of the validation ticks
    ...this.values.validations,
  ]

  connect() {
    const editor = this.editorTarget

    // If the user submits valid code but an invalid form, keep the code showing as valid
    if (this.validatedTarget.value === '1') {
      this.validationsValue.forEach((target) => {
        this.setCheckbox(this[`${target}Target`], true)
      })
      this.hideValidationMessage()
    }

    /*
     * When the user updates code in the editor
     */
    document.addEventListener('editor-codeChanged', () => {
      // Due to a delay with codeChanged being fired
      // this will ensure the code has actually changed
      if (this.codeTarget.value === editor.editorCode) return

      // Update the form fields which will be checked on submission
      this.codeTarget.value = editor.editorCode
      this.validatedTarget.value = '0'

      this.showValidationMessage()

      // Reset any previously validations as they may be invalid
      this.validationsValue.forEach((target) => {
        this.setCheckbox(this[`${target}Target`], false)
      })
    })

    /*
     * When the user has run their code
     */
    document.addEventListener('editor-runCompleted', (e) => {
      this.codeTarget.value = editor.editorCode

      // Check how many validations are required
      const validationsRequired = this.validationsValue.length
      let validationsAchieved = 0

      this.validationsValue.forEach((target) => {
        let targetIsValid = !!e.detail[target]

        this.setCheckbox(this[`${target}Target`], targetIsValid)

        if (targetIsValid) validationsAchieved += 1
      })

      // Echo to the hidden form field if all validations have passed
      this.validatedTarget.value = validationsRequired === validationsAchieved ? '1' : '0'

      this.hideValidationMessage()
    })
  }

  saveCode() {
    this.codeTarget.value = this.editorTarget.editorCode
  }

  showValidationMessage() {
    this.messageTarget.classList.remove('editor__notice--hidden')
  }

  hideValidationMessage() {
    this.messageTarget.classList.add('editor__notice--hidden')
  }

  setCheckbox(elem, value) {
    if (value) {
      elem.classList.add('checkbox--checked')
    } else {
      elem.classList.remove('checkbox--checked')
    }
  }
}
