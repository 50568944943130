import { Controller } from 'stimulus';

export default class extends Controller {
  originalButtonHtml = null

  static values = {
    buttonActioned: String,
    clipboardText: String
  }

  copy(event) {
    if (!this.originalButtonHtml) {
      // avoid copying "Copied!" back into the button after the timeout
      this.originalButtonHtml = event.target.innerHTML;
    }

    navigator.clipboard.writeText(this.clipboardTextValue)
    event.target.innerHTML = this.buttonActionedValue;
    setTimeout(() => { event.target.innerHTML = this.originalButtonHtml }, 2000);
  }
}
