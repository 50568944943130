import { Controller } from 'stimulus'

/**
 * Conditionally show or hide fieldsets based on the selected value of a given element.
 *
 * Uses Stimulus.
 * @see https://stimulus.hotwired.dev
 *
 * Any form that has been rendered with the `FormsHelper.astro_form_with` method will automatically have the necessary
 * data attributes applied to work with this controller. If you need to set them up manually, add the following to your
 * <form> element:
 *
 * data: {
 *   controller: :form,
 *   form_any_value: :ANY_VALUE,
 *   form_fieldset_hidden_class: 'fieldset--hidden'
 * }
 *
 * Any form with the `data-controller="form"` attribute will then be set up with this script.
 * @see https://stimulus.hotwired.dev/reference/controllers
 */
export default class extends Controller {
  /**
   * Set up CSS classes
   * Applied to the form in FormsHelper.astro_form_with
   * @see https://stimulus.hotwired.dev/reference/css-classes
   */
  static classes = ['fieldsetHidden']

  /**
   * Set up element "target"
   * Applied to the trigger elements and fieldsets in the form
   * @see https://stimulus.hotwired.dev/reference/targets
   */
  static targets = ['fieldset', 'trigger']

  /**
   * Set up element "values"
   * Applied to the form in FormsHelper.astro_form_with
   * @see https://stimulus.hotwired.dev/reference/values
   */
  static values = {
    any: String,
  }

  /**
   * Sets up the initial state of fieldsets in forms. Uses Stimulus' .connect lifecycle method
   * @see https://stimulus.hotwired.dev/reference/lifecycle-callbacks
   *
   * On page load:
   *   ... hide all fieldsets ...
   *   ... then loop through all triggers, and fire their event handler for each
   */
  connect() {
    this.fieldsetTargets.forEach((fieldset) => this.fieldsetHide(fieldset))
    this.triggerTargets.forEach((trigger) => this.triggerEventHandler(trigger))
  }

  /**
   * The callback for a form element's `change` event. The element requires the following data attributes:
   *
   * @example
   *   data: {
   *     action: 'change->form#triggerChange',
   *     form_target: :trigger,
   *     trigger_name: :theme
   *   }
   *
   * data-action:
   *   *must* have a value of 'change->form#triggerChange'
   *   The "triggerChange" refers to the {triggerChange} function in this file
   * data-form-target
   *   *must* have a value of 'trigger'
   * data-trigger-name
   *   an arbitrary name that your fieldsets will attempt to match with when determining whether or not to show/hide
   *
   * Fieldsets can be set up with the `field_set_for` helper in FormBuilder::Fields - see the documentation there for
   * more details, but to summarise:
   *
   * @example
   *   <%= form.field_set_for(trigger, value: nil, &block) %>
   *
   * `trigger`
   *    *must* match the value of the `data-trigger-name` attribute on your element
   * `value`
   *    (optional) the fieldset will show if the supplied value matches the value of the current trigger
   *    defaults to the same value as `this.anyValue`
   * @see FormBuilder::Fields.field_set_for
   *
   * @param {Object} event The `change` event triggered by a user's interaction with a form element
   */
  triggerChange = (event) => {
    this.triggerEventHandler(event.target)
  }

  /**
   * Reads a given trigger, and determines whether or not to attempt to update fieldsets.
   * @param {Object} trigger The trigger element
   */
  triggerEventHandler = (trigger) => {
    const triggerType = trigger.getAttribute('type')

    // skip if trigger is a checkbox, and is not checked
    if (triggerType === 'checkbox' && !trigger.checked) {
      return
    }

    // skip if trigger is a radio button, and is not selected
    if (triggerType === 'radio' && !trigger.checked) {
      return
    }

    // Retrieve trigger name and value, to be passed to the fieldsets
    const triggerValue = trigger.value
    const { triggerName } = trigger.dataset

    // Loop through all fieldset targets, and call this.fieldsetUpdate for each
    this.fieldsetTargets.forEach((fieldset) => {
      this.fieldsetUpdate(fieldset, triggerName, triggerValue)
    })
  }

  /**
   * Hide a fieldset.
   *
   * @param {Object} fieldsetElement The fieldset element to hide
   *
   * Adds the fieldsetHiddenClass (defined in the `static classes` array)
   * Prevents the form from submitting the fieldset data
   */
  fieldsetHide(fieldsetElement) {
    const fieldset = fieldsetElement

    fieldset.classList.add(this.fieldsetHiddenClass)
    fieldset.disabled = true
  }

  /**
   * Show a fieldset.
   *
   * @param {Object} fieldsetElement The fieldset element to show
   *
   * Removes the fieldsetHiddenClass (defined in the `static classes` array)
   * Allows the form to submit the fieldset data
   */
  fieldsetShow(fieldsetElement) {
    const fieldset = fieldsetElement

    fieldset.classList.remove(this.fieldsetHiddenClass)
    fieldset.disabled = false
  }

  /**
   * Conditionally update a fieldset based on the name and value of the current trigger
   *
   * @param {Object} fieldsetElement The fieldset element to show
   * @param {String} triggerName The trigger that this fieldset is looking for
   * @param {Object} triggerValue The value for which this fieldset will be shown
   *
   * Calls `return` early if the `triggerName` does not match the `listenFor` set in the fieldset data
   *
   * Hides the fieldset by default, assuming that it does not need to be shown.
   * Determines if the fieldset's does need to be shown by comparing its `showIf` value to `triggerValue`.
   */
  fieldsetUpdate(fieldset, triggerName, triggerValue) {
    const { listenFor, showIf } = fieldset.dataset

    // skip if fieldset is not listening for this trigger
    if (listenFor !== triggerName) {
      return
    }

    // hide the fieldset...
    this.fieldsetHide(fieldset)

    // ...and show it, if the trigger value is what the fieldset is listening for
    if ([this.anyValue, triggerValue].includes(showIf)) {
      this.fieldsetShow(fieldset)
    }
  }
}
