import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

const application = Application.start()

const context = require.context('../controllers', true, /_controller\.js$/)
const componentControllers = require.context('../../components', true, /_controller\.js$/)
const pageControllers = require.context('../../pages', true, /_controller\.js$/)

application.load(
  definitionsFromContext(context)
    .concat(definitionsFromContext(componentControllers))
    .concat(definitionsFromContext(pageControllers))
)
